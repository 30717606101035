<template>
  <a-config-provider :locale="locale" :theme="{
      token: {
        colorPrimary: '#3875F6',  //  主题色
      },
    }">
    <router-view v-if="isRouterAlive"/>
  </a-config-provider>
</template>

<script>

import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
export default {
  name:'App',
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },
  created() {
  },
  mounted(){
    //  项目场景--projectScene
    //  carryglobal.work
    //  carryglobal.vip
    //  localhost
    let projectScene = 0
    console.log('host:', window.location.host)
    //  根据当前域名来判断登录的系统
    let domain = window.location.host
    if(domain.includes('carryglobal.work')){
      //  大后台
      projectScene = 2
      document.title = 'CarryGlobal.work'
    } else if (domain.includes('carryglobal.vip')){
      //  PC端
      projectScene = 1
      document.title = 'CarryGlobal.vip'
    } else if(domain.includes('localhost')) {
      //  开发环境
      projectScene = 0
    }
    this.$store.commit('setProjectScene', projectScene)
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function (){
        this.isRouterAlive = true;
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #222;
  height: 100vh;
  min-width: 1080px;
}

.ant-modal div[aria-hidden="true"] {
  display: none !important;
}

</style>
